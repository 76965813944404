form {
  margin: 20px auto;
  max-width: 600px;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.3); 
}

.form-header {
  background-color: #007bff;
  color: #fff;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.container {
  margin: 0 auto;
}